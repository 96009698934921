<template>
  <div>
    <div v-if="isPC" class="home-pc">
      <div class="circle-bg01"></div>
      <div class="circle-bg02"></div>
      <div class="circle-bg03"></div>
      <div class="circle-bg04"></div>
      <div class="nav-bar">
        <div class="left">
          <div class="logo-box">
            <img class="logo-img" src="../assets/imgs/logo.png" alt="">
            <span class="logo-text">GREEKS.LIVE</span>
          </div>

          <div class="nav-item-box">
            <div class="nav-item dropdown">
              <span class="text">{{$t('message.trade')}}</span>
              <div class="arrow"></div>
              <div class="dropdown-menu" style="width: 140px;">
                <div class="dropdown-menu-item" @click="gotoBlank('https://greeks.live/')">{{$t('message.baseTrade')}}</div>
                <div class="dropdown-menu-item" @click="gotoBlank('http://greeks.live/blocktrade')">{{$t('message.englishTextThree')}}</div>
              </div>
            </div>
            <div class="nav-item">
              <span class="text" style="cursor: pointer;" @click="gotoBlank('https://twisty-sign-c8b.notion.site/Greeks-live-98f66a38fdaa487bbf902e0110b0fffc')">{{$t('message.englishTextFour')}}</span>
            </div>
            <div class="nav-item dropdown">
              <span class="text">{{$t('message.englishTextFive')}}</span>
              <div class="arrow"></div>
              <div class="dropdown-menu" style="width: 160px;">
                <div class="dropdown-menu-item" @click="gotoBlank('https://twitter.com/greekslive?s=11&t=8gbqBNwYvjWYE3nM-8r9XQ')">{{$t('message.englishTextSix')}}</div>
                <div class="dropdown-menu-item" @click="gotoBlank('https://t.me/greekslivecn')">{{$t('message.englishTextSeven')}}</div>
                <div class="dropdown-menu-item" @click="gotoBlank('https://t.me/greekslive')">{{$t('message.englishTextEight')}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <!--          <div class="btn">{{$t('message.englishTextTen')}}</div>-->
          <!--          <div class="btn">{{$t('message.englishTextEleven')}}</div>-->
          <div class="download-app-btn" @click="gotoDownloadAppPage">{{$t('message.downloadApp')}}</div>
          <div class="dropdown" style="height: 100%;">
            <img style="width: 24px;height: 24px;" src="../assets/imgs/lang.png" alt="">
            <div class="dropdown-menu-right" style="width: 120px;">
              <div class="dropdown-menu-item" :class="lang === 'zh' ? 'lang-selected' : ''" @click="toggleLang('zh')">简体中文</div>
              <div class="dropdown-menu-item" :class="lang === 'zh_tc' ? 'lang-selected' : ''" @click="toggleLang('zh_tc')">繁体中文</div>
              <div class="dropdown-menu-item" :class="lang === 'en' ? 'lang-selected' : ''" @click="toggleLang('en')">English</div>
            </div>
          </div>
        </div>
      </div>
      <div class="set-index section01">
        <div class="section-content">
          <div class="title-box">
            <div class="title">{{$t('message.englishTextOne')}}</div>
            <div class="sub-title">{{$t('message.subtitle')}}</div>
            <div class="exchange-box">
              <div class="exchange" @click="gotoTrade('deribit')">
                <img style="width: 88px;height: 23px;" src="../assets/imgs/deribit.png" alt="">
              </div>
              <div class="exchange" @click="gotoTrade('bit')">
                <img style="width: 37px;height: 20px;" src="../assets/imgs/bit.png" alt="">
              </div>
              <div class="exchange" @click="gotoTrade('bybit')">
                <img style="width: 61px;height: 20px;" src="../assets/imgs/bybit.png" alt="">
              </div>
              <div class="exchange" @click="gotoTrade('okx')">
                <img style="width: 56px;height: 17px;" src="../assets/imgs/okx.png" alt="">
              </div>
            </div>
          </div>
          <img v-if="lang === 'en'" class="right-bg" v-lazy="require('../assets/imgs/en/bg02.png')" alt="">
          <img v-else class="right-bg" v-lazy="require('../assets/imgs/zh/bg02.png')" alt="">
        </div>
        <div class="join-tg" @click="joinTg">
          {{$t('message.joinTg')}}
          <img style="position:absolute;top: 10px;left:20px;width: 20px;height: 18px;margin-right: 9px;" v-lazy="require('../assets/imgs/tg.png')" alt="">
        </div>
      </div>
      <div class="set-index section-title" style="margin-bottom: 60px;">Greeks.live {{$t('message.englishTextOne')}}</div>
      <div class="set-index running-time-box">
        <div class="running-box">
          <div class="running-label">{{$t('message.startDate')}}</div>
          <div class="running-data">2020.04.23</div>
        </div>
        <div class="divide-line"></div>
        <div class="running-box">
          <div class="running-label">{{$t('message.runningTime')}}</div>
          <div class="running-data" style="display: flex;align-items: flex-start;">
            <span>{{safeRunningDays}}</span>
            <span style="font-size: 20px;margin-top: 8px;">&nbsp;{{$t('message.days')}}</span>
          </div>
        </div>
      </div>
      <div class="set-index section-title" style="margin-bottom: 60px;">{{$t('message.backed')}}</div>
      <div class="set-index partner-box">
        <div class="partner-item">
          <img style="width: 150px;height: 150px;" v-lazy="require('../assets/imgs/partner01@2x.png')" alt="">
          <div class="partner-name">Deribit</div>
        </div>
        <div class="partner-item">
          <img style="width: 150px;height: 150px;" v-lazy="require('../assets/imgs/partner02@2x.png')" alt="">
          <div class="partner-name">BIT Crypto Exchange</div>
        </div>
        <div class="partner-item">
          <img style="width: 150px;height: 150px;" v-lazy="require('../assets/imgs/partner03@2x.png')" alt="">
          <div class="partner-name">NGC Ventures</div>
        </div>
        <div class="partner-item">
          <img style="width: 150px;height: 150px;" v-lazy="require('../assets/imgs/partner04@2x.png')" alt="">
          <div class="partner-name">SIG</div>
        </div>
      </div>
      <div class="set-index section-title" style="margin: 16px 0 19px;">{{$t('message.relatedProducts')}}</div>
      <div class="set-index production-list" @mouseenter="clearTimer" @mouseleave="slide">
        <div class="production" :class="curProduct === 'web' ? 'selected' : ''" @click="curProduct = 'web'">{{$t('message.baseTrade')}}</div>
        <div class="production" :class="curProduct === 'blocktrade' ? 'selected' : ''" @click="curProduct = 'blocktrade'">{{$t('message.englishTextThree')}}</div>
        <div class="production" :class="curProduct === 'socialTrading' ? 'selected' : ''" @click="curProduct = 'socialTrading'">{{$t('message.socialTrading')}}</div>
        <div class="production" :class="curProduct === 'advancedTradingTools' ? 'selected' : ''" @click="curProduct = 'advancedTradingTools'">{{$t('message.englishTextThirty02')}}</div>
        <div class="production" :class="curProduct === 'webOld' ? 'selected' : ''" @click="curProduct = 'webOld'">{{$t('message.baseTrade')}} ({{$t('message.oldVer2')}})</div>
      </div>
      <div class="set-index production-content" @mouseenter="clearTimer" @mouseleave="slide">
        <template v-if="lang === 'en'">
          <img v-if="curProduct === 'web'" class="left-production-img" src="../assets/imgs/en/production01@2x.png" alt="">
          <img v-if="curProduct === 'blocktrade'" class="left-production-img" src="../assets/imgs/en/production02@2x.png" alt="">
          <img v-if="curProduct === 'advancedTradingTools'" class="left-production-img" src="../assets/imgs/en/production03@2x.png" alt="">
          <img v-if="curProduct === 'socialTrading'" class="left-production-img" src="../assets/imgs/en/production04@2x.png" alt="">
          <img v-if="curProduct === 'webOld'" class="left-production-img" src="../assets/imgs/en/production01@2x.png" alt="">
        </template>
        <template v-else>
          <img v-if="curProduct === 'web'" class="left-production-img" src="../assets/imgs/zh/production01@2x.png" alt="">
          <img v-if="curProduct === 'blocktrade'" class="left-production-img" src="../assets/imgs/zh/production02@2x.png" alt="">
          <img v-if="curProduct === 'advancedTradingTools'" class="left-production-img" src="../assets/imgs/zh/production03@2x.png" alt="">
          <img v-if="curProduct === 'socialTrading'" class="left-production-img" src="../assets/imgs/zh/production04@2x.png" alt="">
          <img v-if="curProduct === 'webOld'" class="left-production-img" src="../assets/imgs/zh/production01@2x.png" alt="">
        </template>
        <div class="download-box" v-if="curProduct !== 'advancedTradingTools'">
          <img v-if="curProduct === 'web'" class="qr-code" src="../assets/imgs/qrcode_basicTrade.png" alt="">
          <img v-if="curProduct === 'blocktrade'" class="qr-code" src="../assets/imgs/qrcode_blockTrade.png" alt="">
          <img v-if="curProduct === 'socialTrading'" class="qr-code" src="../assets/imgs/qrcode_social_trade.png" alt="">
          <div class="download-btn" @click="downloadApp('ios',curProduct)" @contextmenu.prevent="handleRightClick('ios',curProduct)">
            <img style="width: 20px;height: 24px;margin-right: 9px;margin-left: 10px;" src="../assets/imgs/ios@2x.png" alt="">
            <div>
              <div style="font-size: 12px;">Download on the</div>
              <div style="font-size: 14px;">APP Store</div>
            </div>
          </div>
          <div class="download-btn" @click="downloadApp('android',curProduct)" @contextmenu.prevent="handleRightClick('android',curProduct)">
            <img style="width: 20px;height: 22px;margin-right: 7px;margin-left: 10px;" src="../assets/imgs/android@2x.png" alt="">
            <div style="display: flex;justify-content: center;align-items: center;">
              <div style="font-size: 14px;">Android</div>
              <div style="font-size: 12px;height: 14px;border-radius: 7px;display: flex;justify-content: center;align-items: center;background: #ffffff;color: black;padding: 0 4px;margin-left: 7px;">APK</div>
            </div>
          </div>
          <div v-if="curProduct === 'web'" class="download-btn to-block-btn" @click="gotoBlank('https://www.greeks.live')">{{$t('message.officialWebsite')}} →</div>
          <div v-if="curProduct === 'blocktrade'" class="download-btn to-block-btn" @click="gotoBlank('http://greeks.live/blocktrade')">{{$t('message.officialWebsite')}} →</div>
          <div v-if="curProduct === 'advancedTradingTools'" class="download-btn to-block-btn" @click="gotoBlank('https://www.greeks.live/#/deribit/advance_tools/ddh')">{{$t('message.officialWebsite')}} →</div>
          <div v-if="curProduct === 'socialTrading'" class="download-btn to-block-btn" @click="gotoBlank('https://t.greeks.live')">{{$t('message.officialWebsite')}} →</div>
          <div v-if="curProduct === 'webOld'" class="download-btn to-block-btn" @click="gotoBlank('https://www.greeks.live')">{{$t('message.officialWebsite')}} →</div>
        </div>
        <div class="ddh-box" v-else>
          <div class="ddh-title">{{$t('message.englishTextThirty02')}}</div>
          <!--        <div class="ddh-description">描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述</div>-->
          <div class="download-btn to-block-btn" @click="gotoBlank('https://www.greeks.live/#/deribit/advance_tools/ddh')">{{$t('message.officialWebsite')}} →</div>
        </div>
      </div>
      <div class="set-index section-title" style="margin-bottom: 60px;">{{$t('message.whyOptions')}}</div>
      <div class="set-index why-options-list-wrapper" @mouseenter="clearTimer2" @mouseleave="slide2">
        <div class="left-arrow" @click="prev"></div>
        <div class="slider-box">
          <div ref="slide" class="why-options-list">
            <div class="why-options-item">
              <img style="position:absolute;top:0;left:0;width: 100%;height: 100%;" v-lazy="require('../assets/imgs/whyOptions01@2x.png')" alt="">
              <div style="position:absolute;top:16px;right:16px;left:16px;font-size: 18px;">{{$t('message.whyOptionsDesc01')}}</div>
            </div>
            <div class="why-options-item">
              <img style="position:absolute;top:0;left:0;width: 100%;height: 100%;" v-lazy="require('../assets/imgs/whyOptions02@2x.png')" alt="">
              <div style="position:absolute;top:16px;right:16px;left:16px;font-size: 18px;">{{$t('message.whyOptionsDesc02')}}</div>
            </div>
            <div class="why-options-item">
              <img style="position:absolute;top:0;left:0;width: 100%;height: 100%;" v-lazy="require('../assets/imgs/whyOptions03@2x.png')" alt="">
              <div style="position:absolute;top:16px;right:16px;left:16px;font-size: 18px;">{{$t('message.whyOptionsDesc03')}}</div>
            </div>
            <div class="why-options-item">
              <img style="position:absolute;top:0;left:0;width: 100%;height: 100%;" v-lazy="require('../assets/imgs/whyOptions04@2x.png')" alt="">
              <div style="position:absolute;top:16px;right:16px;left:16px;font-size: 18px;">{{$t('message.whyOptionsDesc04')}}</div>
            </div>
            <div class="why-options-item">
              <img style="position:absolute;top:0;left:0;width: 100%;height: 100%;" v-lazy="require('../assets/imgs/whyOptions01@2x.png')" alt="">
              <div style="position:absolute;top:16px;right:16px;left:16px;font-size: 18px;">{{$t('message.whyOptionsDesc01')}}</div>
            </div>
            <div class="why-options-item">
              <img style="position:absolute;top:0;left:0;width: 100%;height: 100%;" v-lazy="require('../assets/imgs/whyOptions02@2x.png')" alt="">
              <div style="position:absolute;top:16px;right:16px;left:16px;font-size: 18px;">{{$t('message.whyOptionsDesc02')}}</div>
            </div>
            <div class="why-options-item">
              <img style="position:absolute;top:0;left:0;width: 100%;height: 100%;" v-lazy="require('../assets/imgs/whyOptions03@2x.png')" alt="">
              <div style="position:absolute;top:16px;right:16px;left:16px;font-size: 18px;">{{$t('message.whyOptionsDesc03')}}</div>
            </div>
            <div class="why-options-item">
              <img style="position:absolute;top:0;left:0;width: 100%;height: 100%;" v-lazy="require('../assets/imgs/whyOptions04@2x.png')" alt="">
              <div style="position:absolute;top:16px;right:16px;left:16px;font-size: 18px;">{{$t('message.whyOptionsDesc04')}}</div>
            </div>
            <div class="why-options-item">
              <img style="position:absolute;top:0;left:0;width: 100%;height: 100%;" v-lazy="require('../assets/imgs/whyOptions01@2x.png')" alt="">
              <div style="position:absolute;top:16px;right:16px;left:16px;font-size: 18px;">{{$t('message.whyOptionsDesc01')}}</div>
            </div>
            <div class="why-options-item">
              <img style="position:absolute;top:0;left:0;width: 100%;height: 100%;" v-lazy="require('../assets/imgs/whyOptions02@2x.png')" alt="">
              <div style="position:absolute;top:16px;right:16px;left:16px;font-size: 18px;">{{$t('message.whyOptionsDesc02')}}</div>
            </div>
            <div class="why-options-item">
              <img style="position:absolute;top:0;left:0;width: 100%;height: 100%;" v-lazy="require('../assets/imgs/whyOptions03@2x.png')" alt="">
              <div style="position:absolute;top:16px;right:16px;left:16px;font-size: 18px;">{{$t('message.whyOptionsDesc03')}}</div>
            </div>
            <div class="why-options-item">
              <img style="position:absolute;top:0;left:0;width: 100%;height: 100%;" v-lazy="require('../assets/imgs/whyOptions04@2x.png')" alt="">
              <div style="position:absolute;top:16px;right:16px;left:16px;font-size: 18px;">{{$t('message.whyOptionsDesc04')}}</div>
            </div>
          </div>
        </div>
        <div class="right-arrow" @click="next"></div>
      </div>
      <div class="set-index section-title" style="margin-bottom: 60px;">{{$t('message.feature')}}</div>
      <div class="set-index feature-wrapper">
        <div class="feature-box">
          <div class="feature-left">
            <div class="feature-title">{{$t('message.englishTextTwelve')}}</div>
            <div class="feature-description">{{$t('message.englishTextThirteen')}}</div>
            <div class="feature-description">{{$t('message.englishTextFourteen')}}</div>
            <div class="feature-description">{{$t('message.englishTextFifteen')}}</div>
            <div class="feature-description">{{$t('message.englishTextSixteen')}}</div>
            <div class="feature-description">{{$t('message.AutoLendingTool')}}</div>
            <div class="feature-link" @click="gotoBlank('https://www.greeks.live')"><span class="link">www.greeks.live</span></div>
          </div>
          <img v-if="lang === 'en'" class="feature-right" v-lazy="require('../assets/imgs/en/bg04@2x.png')" alt="">
          <img v-else class="feature-right" v-lazy="require('../assets/imgs/zh/bg04@2x.png')" alt="">
        </div>
        <div class="feature-box">
          <div class="feature-left">
            <div class="feature-title">{{$t('message.lengTools')}}</div>
            <div class="feature-description">{{$t('message.lengToolsDesc01')}}</div>
            <div class="feature-description">{{$t('message.lengToolsDesc02')}}</div>
            <div class="feature-description">{{$t('message.lengToolsDesc03')}}</div>
            <div class="feature-description">{{$t('message.lengToolsDesc04')}}</div>
            <div class="feature-description">{{$t('message.lengToolsDesc05')}}</div>
            <div class="feature-link" @click="gotoBlank('https://lend.greeks.live/')"><span class="link">lend.greeks.live</span></div>
          </div>
          <img v-if="lang === 'en'" class="feature-right" v-lazy="require('../assets/imgs/en/leng@2x.png')" alt="">
          <img v-else class="feature-right" v-lazy="require('../assets/imgs/zh/leng@2x.png')" alt="">
        </div>
        <div class="feature-box">
          <div class="feature-left">
            <div class="feature-title">{{$t('message.englishTextSeventeen')}}</div>
            <div class="feature-description">{{$t('message.englishTextEighteen')}}</div>
            <div class="feature-description">{{$t('message.englishTextNineteen')}}</div>
            <div class="feature-description">{{$t('message.englishTextTwenty')}}</div>
            <div class="feature-description">{{$t('message.englishTextTwentyOne')}}</div>
            <div class="feature-link" @click="gotoBlank('https://pv.greeks.live')"><span class="link">pv.greeks.live</span></div>
          </div>
          <img v-if="lang === 'en'" class="feature-right" v-lazy="require('../assets/imgs/en/bg07@2x.png')" alt="">
          <img v-else class="feature-right" v-lazy="require('../assets/imgs/zh/bg07@2x.png')" alt="">
        </div>
        <div class="feature-box" style="margin-bottom: 180px;">
          <div class="feature-left">
            <div class="feature-title">{{$t('message.englishTextTwentyFour')}}</div>
            <div class="feature-description">{{$t('message.englishTextTwentyFive')}}</div>
            <div class="feature-description">{{$t('message.englishTextTwentySix')}}</div>
            <div class="feature-description">{{$t('message.englishTextTwentySeven')}}</div>
            <div class="feature-description">{{$t('message.englishTextTwentyEight')}}</div>
            <div class="feature-description">{{$t('message.dataLabDesc06')}}</div>
            <div class="feature-link" @click="gotoBlank('https://data.greeks.live')"><span class="link">data.greeks.live</span></div>
            <div class="learn-link" @click="gotoBlank('https://greekslive.notion.site/Greeks-live-Data-Lab-Introduction-ac7f9da5f2634dda875c52107e953959')"><span class="link">{{$t('message.learnMoreDatalab')}}</span></div>
          </div>
          <div v-if="lang === 'en'" class="feature-right" style="position: relative">
            <img style="width: 100%;height: 100%;position: absolute;top: 0;left: 0;z-index: 9;" v-lazy="require('../assets/imgs/en/bg06@2x.png')" alt="">
            <img style="width: 100%;height: 100%;position: absolute;top: 74px;left: 74px;z-index: 8;" v-lazy="require('../assets/imgs/en/bg05@2x.png')" alt="">
          </div>
          <div v-else class="feature-right" style="position: relative">
            <img style="width: 100%;height: 100%;position: absolute;top: 0;left: 0;z-index: 9;" v-lazy="require('../assets/imgs/zh/bg06@2x.png')" alt="">
            <img style="width: 100%;height: 100%;position: absolute;top: 74px;left: 74px;z-index: 8;" v-lazy="require('../assets/imgs/zh/bg05@2x.png')" alt="">
          </div>
        </div>
        <div class="feature-box">
          <div class="feature-left">
            <div class="feature-title">{{$t('message.englishTextTwentyNine')}}</div>
            <div class="feature-description">{{$t('message.playBackDesc01')}}</div>
            <div class="feature-description">{{$t('message.playBackDesc02')}}</div>
            <div class="feature-link" @click="gotoBlank('https://playback.greeks.live')"><span class="link">playback.greeks.live</span></div>
            <div class="learn-link" @click="gotoBlank('https://twitter.com/GreeksLive/status/1717137845708870141?s=20')"><span class="link">{{$t('message.learnMorePlayback')}}</span></div>
          </div>
          <img v-if="lang === 'en'" class="feature-right" v-lazy="require('../assets/imgs/en/bg08@2x.png')" alt="">
          <img v-else class="feature-right" v-lazy="require('../assets/imgs/zh/bg08@2x.png')" alt="">
        </div>
        <div class="feature-box">
          <div class="feature-left">
            <div class="feature-title">{{$t('message.englishTextThirty')}}</div>
            <div class="feature-description">{{$t('message.advanceToolsDesc01')}}</div>
            <div class="feature-description">{{$t('message.advancedToolsDesc02')}}</div>
            <div class="feature-description">{{$t('message.advancedToolsDesc03')}}</div>
            <div class="feature-description">{{$t('message.advancedToolsDesc04')}}</div>
            <div class="feature-link" @click="gotoBlank('https://tools.greeks.live')"><span class="link">tools.greeks.live</span></div>
          </div>
          <img v-if="lang === 'en'" class="feature-right" v-lazy="require('../assets/imgs/en/bg09@2x.png')" alt="">
          <img v-else  class="feature-right" v-lazy="require('../assets/imgs/zh/bg09@2x.png')" alt="">
        </div>
      </div>
      <div class="set-index bottom-box">
        <div class="logo-box">
          <img class="logo-img" src="../assets/imgs/logo.png" alt="">
          <span class="logo-text">GREEKS.LIVE</span>
        </div>
        <div class="contact-box">
          <div style="font-size: 14px;color: #AEAEAE;cursor: pointer;" @click="$router.push('/terms_of_service')">{{$t('message.englishTextThirtyOne')}}</div>
          <div class="contact-btn-box">
            <div class="contact-btn" @click="gotoBlank('https://twitter.com/greekslive?s=11&t=8gbqBNwYvjWYE3nM-8r9XQ')">
              <img class="selected" style="width: 100%;height: 100%;" src="../assets/imgs/contact_selected01@2x.png" alt="">
              <img class="unselect" style="width: 100%;height: 100%;" src="../assets/imgs/contact_unselect01@2x.png" alt="">
            </div>
            <div class="contact-btn" @click="joinTg">
              <img class="selected" style="width: 100%;height: 100%;" src="../assets/imgs/contact_selected02@2x.png" alt="">
              <img class="unselect" style="width: 100%;height: 100%;" src="../assets/imgs/contact_unselect02@2x.png" alt="">
            </div>
            <div class="contact-btn" @click="sendEmail">
              <img class="selected" style="width: 100%;height: 100%;" src="../assets/imgs/contact_selected04@2x.png" alt="">
              <img class="unselect" style="width: 100%;height: 100%;" src="../assets/imgs/contact_unselect04@2x.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="home-mobile">
      <div class="circle-bg01"></div>
      <div class="circle-bg02"></div>
      <div class="circle-bg03"></div>
      <div class="circle-bg04"></div>
      <div class="circle-bg05"></div>
      <div class="nav-bar">
        <div class="left">
          <div class="logo-box">
            <img class="logo-img" src="../assets/imgs/logo.png" alt="">
            <span class="logo-text">GREEKS.LIVE</span>
          </div>
        </div>
        <div class="right">
          <div class="download-app-btn" @click="gotoDownloadAppPage">{{$t('message.downloadApp')}}</div>
          <div :class="showNav ? 'dropdown1' : 'dropdown1 dropdown'" style="height: 100%;">
            <img style="width: 24px;height: 24px;" src="../assets/imgs/lang.png" alt="">
            <div class="dropdown-menu-right" style="width: 120px;">
              <div class="dropdown-menu-item" :class="lang === 'zh' ? 'lang-selected' : ''" @click="toggleLang('zh')">简体中文</div>
              <div class="dropdown-menu-item" :class="lang === 'zh_tc' ? 'lang-selected' : ''" @click="toggleLang('zh_tc')">繁体中文</div>
              <div class="dropdown-menu-item" :class="lang === 'en' ? 'lang-selected' : ''" @click="toggleLang('en')">English</div>
            </div>
          </div>
          <img style="width: 22px;height: 19px;margin-left: 15px;" src="../assets/imgs/nav.png" alt="" @click="toggleNav">
        </div>
        <div v-if="showNav" class="dropdown-nav-box" @click="toggleNav">
          <div style="background: #060D0C;">
            <div class="dropdown-nav-item" @click="gotoBlank('https://greeks.live/')">{{$t('message.baseTrade')}}</div>
            <div class="dropdown-nav-item" @click="gotoBlank('http://greeks.live/blocktrade')">{{$t('message.englishTextThree')}}</div>
            <div class="dropdown-nav-item" @click="gotoBlank('https://twisty-sign-c8b.notion.site/Greeks-live-98f66a38fdaa487bbf902e0110b0fffc')">{{$t('message.englishTextFour')}}</div>
            <div class="dropdown-nav-item" @click="gotoBlank('https://twitter.com/greekslive?s=11&t=8gbqBNwYvjWYE3nM-8r9XQ')">{{$t('message.englishTextSix')}}</div>
            <div class="dropdown-nav-item" @click="gotoBlank('https://t.me/greekslivecn')">{{$t('message.englishTextSeven')}}</div>
            <div class="dropdown-nav-item" @click="gotoBlank('https://t.me/greekslive')">{{$t('message.englishTextEight')}}</div>
          </div>
        </div>
      </div>
      <div class="set-index section01">
        <div class="section-content">
          <div class="title-box">
            <div class="title">{{$t('message.englishTextOne')}}</div>
            <div class="sub-title">{{$t('message.subtitle')}}</div>
            <div class="exchange-box">
              <div class="exchange" @click="gotoTrade('deribit')">
                <img style="width: 44px;height: 12px;" v-lazy="require('../assets/imgs/deribit.png')" alt="">
              </div>
              <div class="exchange" @click="gotoTrade('bybit')">
                <img style="width: 31px;height: 10px;" v-lazy="require('../assets/imgs/bybit.png')" alt="">
              </div>
              <div class="exchange" @click="gotoTrade('okx')">
                <img style="width: 28px;height: 9px;" v-lazy="require('../assets/imgs/okx.png')" alt="">
              </div>
            </div>
          </div>
          <div style="display: flex;margin-bottom: 15px;">
            <div class="download-btn" @click="downloadApp('ios','web')" style="margin-right: 10px;">
              <img style="width: 10px;height: 12px;margin-right: 4px;margin-left: 5px;" src="../assets/imgs/ios@2x.png" alt="">
              <div style="font-size: 12px;">APP Store</div>
            </div>
            <div class="download-btn" @click="downloadApp('android','web')">
              <img style="width: 10px;height: 11px;margin-right: 3px;margin-left: 5px;" src="../assets/imgs/android@2x.png" alt="">
              <div style="display: flex;justify-content: center;align-items: center;">
                <div style="font-size: 12px;">Android</div>
                <div style="font-size: 10px;height: 14px;border-radius: 7px;display: flex;justify-content: center;align-items: center;background: #ffffff;color: black;padding: 0 4px;margin-left: 3px;">APK</div>
              </div>
            </div>
          </div>
          <div class="join-tg" @click="joinTg">
            {{$t('message.joinTg')}}
            <img style="position:absolute;top: 6px;left:8px;width: 10px;height: 9px;margin-right: 9px;" v-lazy="require('../assets/imgs/tg.png')" alt="">
          </div>
          <img v-if="lang === 'en'" class="right-bg" v-lazy="require('../assets/imgs/en/bg02.png')" alt="">
          <img v-else class="right-bg" v-lazy="require('../assets/imgs/zh/bg02.png')" alt="">
        </div>
      </div>
      <div class="set-index section-title" style="margin-bottom: 60px;">Greeks.live {{$t('message.englishTextOne')}}</div>
      <div class="set-index running-time-box">
        <div class="running-box">
          <div class="running-label">{{$t('message.startDate')}}</div>
          <div class="running-data">2020.04.23</div>
        </div>
        <div class="divide-line"></div>
        <div class="running-box">
          <div class="running-label">{{$t('message.runningTime')}}</div>
          <div class="running-data" style="display: flex;align-items: flex-start;">
            <span>{{safeRunningDays}}</span>
            <span style="font-size: 20px;margin-top: 8px;">&nbsp;{{$t('message.days')}}</span>
          </div>
        </div>
      </div>
      <div class="set-index section-title" style="margin-bottom: 60px;">{{$t('message.backed')}}</div>
      <div class="set-index partner-box" style="margin-bottom: 43px;">
        <div class="partner-item">
          <img style="width: 80px;height: 80px;" v-lazy="require('../assets/imgs/partner01@2x.png')" alt="">
          <div class="partner-name">Deribit</div>
        </div>
        <div class="partner-item">
          <img style="width: 80px;height: 80px;" v-lazy="require('../assets/imgs/partner02@2x.png')" alt="">
          <div class="partner-name">BIT Crypto Exchange</div>
        </div>
      </div>
      <div class="set-index partner-box" style="margin-bottom: 155px;">
        <div class="partner-item">
          <img style="width: 80px;height: 80px;" v-lazy="require('../assets/imgs/partner03@2x.png')" alt="">
          <div class="partner-name">NGC Ventures</div>
        </div>
        <div class="partner-item">
          <img style="width: 80px;height: 80px;" v-lazy="require('../assets/imgs/partner04@2x.png')" alt="">
          <div class="partner-name">SIG</div>
        </div>
      </div>
      <div class="set-index section-title" style="margin: 16px 0 19px;">{{$t('message.relatedProducts')}}</div>
      <div class="set-index production-row" style="margin-bottom: 59px;">
        <div class="production-item">
          <img v-if="lang === 'en'" class="left-production-img" src="../assets/imgs/en/production01@2x.png" alt="">
          <img v-else class="left-production-img" src="../assets/imgs/zh/production01@2x.png" alt="">

          <div class="to-block-btn" @click="gotoBlank('https://www.greeks.live')">{{$t('message.baseTrade')}} >></div>
          <div class="download-btn" @click="downloadApp('ios','web')">
            <img style="width: 10px;height: 12px;margin-right: 4px;margin-left: 5px;" src="../assets/imgs/ios@2x.png" alt="">
            <div style="font-size: 12px;">APP Store</div>
          </div>
          <div class="download-btn" @click="downloadApp('android','web')">
            <img style="width: 10px;height: 11px;margin-right: 3px;margin-left: 5px;" src="../assets/imgs/android@2x.png" alt="">
            <div style="display: flex;justify-content: center;align-items: center;">
              <div style="font-size: 12px;">Android</div>
              <div style="font-size: 10px;height: 14px;border-radius: 7px;display: flex;justify-content: center;align-items: center;background: #ffffff;color: black;padding: 0 4px;margin-left: 3px;">APK</div>
            </div>
          </div>
        </div>
        <div class="production-item">
          <img v-if="lang === 'en'" class="left-production-img" src="../assets/imgs/en/production02@2x.png" alt="">
          <img v-else class="left-production-img" src="../assets/imgs/zh/production02@2x.png" alt="">

          <div class="to-block-btn" @click="gotoBlank('http://greeks.live/blocktrade')">{{$t('message.englishTextThree')}} >></div>
          <div class="download-btn" @click="downloadApp('ios','blocktrade')">
            <img style="width: 10px;height: 12px;margin-right: 4px;margin-left: 5px;" src="../assets/imgs/ios@2x.png" alt="">
            <div style="font-size: 12px;">APP Store</div>
          </div>
          <div class="download-btn" @click="downloadApp('android','blocktrade')">
            <img style="width: 10px;height: 11px;margin-right: 3px;margin-left: 5px;" src="../assets/imgs/android@2x.png" alt="">
            <div style="display: flex;justify-content: center;align-items: center;">
              <div style="font-size: 12px;">Android</div>
              <div style="font-size: 10px;height: 14px;border-radius: 7px;display: flex;justify-content: center;align-items: center;background: #ffffff;color: black;padding: 0 4px;margin-left: 3px;">APK</div>
            </div>
          </div>
        </div>
      </div>
      <div class="set-index production-row" style="margin-bottom: 59px;">
        <div class="production-item">
          <img v-if="lang === 'en'" class="left-production-img" src="../assets/imgs/en/production04@2x.png" alt="">
          <img v-else class="left-production-img" src="../assets/imgs/zh/production04@2x.png" alt="">

          <div class="to-block-btn" @click="gotoBlank('https://t.greeks.live')">{{$t('message.socialTrading')}} >></div>
          <div class="download-btn" @click="downloadApp('ios','socialTrading')">
            <img style="width: 10px;height: 12px;margin-right: 4px;margin-left: 5px;" src="../assets/imgs/ios@2x.png" alt="">
            <div style="font-size: 12px;">APP Store</div>
          </div>
          <div class="download-btn" @click="downloadApp('android','socialTrading')">
            <img style="width: 10px;height: 11px;margin-right: 3px;margin-left: 5px;" src="../assets/imgs/android@2x.png" alt="">
            <div style="display: flex;justify-content: center;align-items: center;">
              <div style="font-size: 12px;">Android</div>
              <div style="font-size: 10px;height: 14px;border-radius: 7px;display: flex;justify-content: center;align-items: center;background: #ffffff;color: black;padding: 0 4px;margin-left: 3px;">APK</div>
            </div>
          </div>
        </div>
        <div class="production-item">
          <img v-if="lang === 'en'" class="left-production-img" src="../assets/imgs/en/production03@2x.png" alt="">
          <img v-else class="left-production-img" src="../assets/imgs/zh/production03@2x.png" alt="">

          <div class="to-block-btn" @click="gotoBlank('https://www.greeks.live/#/deribit/advance_tools/ddh')">{{$t('message.englishTextThirty02')}} >></div>
          <div style="font-size: 12px;height: 52px;width: 145px;margin-top: 9px;">{{$t('message.advanceToolsTips')}}</div>
        </div>
      </div>
      <div class="set-index production-row" style="margin-bottom: 155px;">
        <div class="production-item">
          <img v-if="lang === 'en'" class="left-production-img" src="../assets/imgs/en/production01@2x.png" alt="">
          <img v-else class="left-production-img" src="../assets/imgs/zh/production01@2x.png" alt="">

          <div class="to-block-btn" @click="gotoBlank('https://www.greeks.live')">{{$t('message.baseTrade')}} ({{$t('message.oldVer2')}})>></div>
          <div class="download-btn" @click="downloadApp('ios','webOld')">
            <img style="width: 10px;height: 12px;margin-right: 4px;margin-left: 5px;" src="../assets/imgs/ios@2x.png" alt="">
            <div style="font-size: 12px;">APP Store</div>
          </div>
          <div class="download-btn" @click="downloadApp('android','webOld')">
            <img style="width: 10px;height: 11px;margin-right: 3px;margin-left: 5px;" src="../assets/imgs/android@2x.png" alt="">
            <div style="display: flex;justify-content: center;align-items: center;">
              <div style="font-size: 12px;">Android</div>
              <div style="font-size: 10px;height: 14px;border-radius: 7px;display: flex;justify-content: center;align-items: center;background: #ffffff;color: black;padding: 0 4px;margin-left: 3px;">APK</div>
            </div>
          </div>
        </div>
        <div class="production-item">
        </div>
      </div>
      <div class="set-index section-title" style="margin-bottom: 60px;">{{$t('message.whyOptions')}}</div>
      <div class="set-index why-options-list" style="margin-bottom: 10px;">
        <div class="why-options-item" style="margin-right: 15px;">
          <img style="position:absolute;top:0;left:0;width: 100%;height: 100%;" v-lazy="require('../assets/imgs/whyOptions01@2x.png')" alt="">
          <div style="position:absolute;top:16px;right:16px;left:16px;">{{$t('message.whyOptionsDesc01')}}</div>
        </div>
        <div class="why-options-item">
          <img style="position:absolute;top:0;left:0;width: 100%;height: 100%;" v-lazy="require('../assets/imgs/whyOptions02@2x.png')" alt="">
          <div style="position:absolute;top:16px;right:16px;left:16px;">{{$t('message.whyOptionsDesc02')}}</div>
        </div>
      </div>
      <div class="set-index why-options-list" style="margin-bottom: 155px;">
        <div class="why-options-item" style="margin-right: 15px;">
          <img style="position:absolute;top:0;left:0;width: 100%;height: 100%;" v-lazy="require('../assets/imgs/whyOptions03@2x.png')" alt="">
          <div style="position:absolute;top:16px;right:16px;left:16px;">{{$t('message.whyOptionsDesc03')}}</div>
        </div>
        <div class="why-options-item">
          <img style="position:absolute;top:0;left:0;width: 100%;height: 100%;" v-lazy="require('../assets/imgs/whyOptions04@2x.png')" alt="">
          <div style="position:absolute;top:16px;right:16px;left:16px;">{{$t('message.whyOptionsDesc04')}}</div>
        </div>
      </div>
      <div class="set-index section-title" style="margin-bottom: 60px;">{{$t('message.feature')}}</div>
      <div class="set-index feature-wrapper" style="margin-bottom: 35px;" @mouseenter="clearTimer3" @mouseleave="slide3">
        <div class="toggle-feature-btn-box">
          <span>{{curHotFeature}}/6</span>
          <img v-if="curHotFeature !== 6" @click="changeHotFeature(true)" style="height: 44px;width: 8px;margin-right: 5px;margin-bottom: 11px;" src="../assets/imgs/arrow02@2x.png" alt="">
          <img v-else @click="changeHotFeature(true)" style="height: 44px;width: 8px;transform: rotate(180deg);margin-right: 5px;margin-bottom: 11px;" src="../assets/imgs/arrow02_disabled@2x.png" alt="">
          <img v-if="curHotFeature !== 1" @click="changeHotFeature(false)" style="height: 44px;width: 8px;transform: rotate(180deg);margin-left: 5px;margin-bottom: -11px;" src="../assets/imgs/arrow02@2x.png" alt="">
          <img v-else @click="changeHotFeature(false)" style="height: 44px;width: 8px;margin-left: 5px;margin-bottom: -11px;" src="../assets/imgs/arrow02_disabled@2x.png" alt="">
        </div>
        <transition name="slide-fade" mode="out-in" appear>
          <div v-if="curHotFeature === 1" key="1" class="feature-box">
            <img v-if="lang === 'en'" class="feature-right" v-lazy="require('../assets/imgs/en/bg04@2x.png')" alt="">
            <img v-else class="feature-right" v-lazy="require('../assets/imgs/zh/bg04@2x.png')" alt="">
            <div class="feature-left">
              <div class="feature-title">{{$t('message.englishTextTwelve')}}</div>
              <div class="feature-description">{{$t('message.englishTextThirteen')}}</div>
              <div class="feature-description">{{$t('message.englishTextFourteen')}}</div>
              <div class="feature-description">{{$t('message.englishTextFifteen')}}</div>
              <div class="feature-description">{{$t('message.englishTextSixteen')}}</div>
              <div class="feature-description">{{$t('message.AutoLendingTool')}}</div>
              <div class="feature-link" @click="gotoBlank('https://www.greeks.live')"><span class="link">www.greeks.live</span></div>
            </div>
          </div>
          <div v-if="curHotFeature === 2" key="2" class="feature-box">
            <img v-if="lang === 'en'" class="feature-right" v-lazy="require('../assets/imgs/en/leng@2x.png')" alt="">
            <img v-else class="feature-right" v-lazy="require('../assets/imgs/zh/leng@2x.png')" alt="">
            <div class="feature-left">
              <div class="feature-title">{{$t('message.lengTools')}}</div>
              <div class="feature-description">{{$t('message.lengToolsDesc01')}}</div>
              <div class="feature-description">{{$t('message.lengToolsDesc02')}}</div>
              <div class="feature-description">{{$t('message.lengToolsDesc03')}}</div>
              <div class="feature-description">{{$t('message.lengToolsDesc04')}}</div>
              <div class="feature-description">{{$t('message.lengToolsDesc05')}}</div>
              <div class="feature-link" @click="gotoBlank('https://lend.greeks.live/')"><span class="link">lend.greeks.live</span></div>
            </div>
          </div>
          <div v-if="curHotFeature === 3" key="3" class="feature-box">
            <img v-if="lang === 'en'" class="feature-right" v-lazy="require('../assets/imgs/en/bg07@2x.png')" alt="">
            <img v-else class="feature-right" v-lazy="require('../assets/imgs/zh/bg07@2x.png')" alt="">
            <div class="feature-left">
              <div class="feature-title">{{$t('message.englishTextSeventeen')}}</div>
              <div class="feature-description">{{$t('message.englishTextEighteen')}}</div>
              <div class="feature-description">{{$t('message.englishTextNineteen')}}</div>
              <div class="feature-description">{{$t('message.englishTextTwenty')}}</div>
              <div class="feature-description">{{$t('message.englishTextTwentyOne')}}</div>
              <div class="feature-link" @click="gotoBlank('https://pv.greeks.live')"><span class="link">pv.greeks.live</span></div>
            </div>
          </div>
          <div v-if="curHotFeature === 4" key="4" class="feature-box">
            <div v-if="lang === 'en'" class="feature-right" style="position: relative">
              <img style="width: 100%;height: 100%;position: absolute;top: 0;left: 0;z-index: 9;" v-lazy="require('../assets/imgs/en/bg06@2x.png')" alt="">
              <img style="width: 100%;height: 100%;position: absolute;top: 25px;left: 25px;z-index: 8;" v-lazy="require('../assets/imgs/en/bg05@2x.png')" alt="">
            </div>
            <div v-else class="feature-right" style="position: relative">
              <img style="width: 100%;height: 100%;position: absolute;top: 0;left: 0;z-index: 9;" v-lazy="require('../assets/imgs/zh/bg06@2x.png')" alt="">
              <img style="width: 100%;height: 100%;position: absolute;top: 25px;left: 25px;z-index: 8;" v-lazy="require('../assets/imgs/zh/bg05@2x.png')" alt="">
            </div>
            <div class="feature-left">
              <div class="feature-title">{{$t('message.englishTextTwentyFour')}}</div>
              <div class="feature-description">{{$t('message.englishTextTwentyFive')}}</div>
              <div class="feature-description">{{$t('message.englishTextTwentySix')}}</div>
              <div class="feature-description">{{$t('message.englishTextTwentySeven')}}</div>
              <div class="feature-description">{{$t('message.englishTextTwentyEight')}}</div>
              <div class="feature-description">{{$t('message.dataLabDesc06')}}</div>
              <div class="feature-link" @click="gotoBlank('https://data.greeks.live')"><span class="link">data.greeks.live</span></div>
              <div>
                <div class="learn-link" @click="gotoBlank('https://greekslive.notion.site/Greeks-live-Data-Lab-Introduction-ac7f9da5f2634dda875c52107e953959')"><span class="link">{{$t('message.learnMoreDatalab')}}</span></div>
              </div>
            </div>
          </div>
          <div v-if="curHotFeature === 5" key="5" class="feature-box">
            <img v-if="lang === 'en'" class="feature-right" v-lazy="require('../assets/imgs/en/bg08@2x.png')" alt="">
            <img v-else class="feature-right" v-lazy="require('../assets/imgs/zh/bg08@2x.png')" alt="">
            <div class="feature-left">
              <div class="feature-title">{{$t('message.englishTextTwentyNine')}}</div>
              <div class="feature-description">{{$t('message.playBackDesc01')}}</div>
              <div class="feature-description">{{$t('message.playBackDesc02')}}</div>
              <div class="feature-link" @click="gotoBlank('https://playback.greeks.live')"><span class="link">playback.greeks.live</span></div>
              <div>
                <div class="learn-link" @click="gotoBlank('https://twitter.com/GreeksLive/status/1717137845708870141?s=20')"><span class="link">{{$t('message.learnMorePlayback')}}</span></div>
              </div>
            </div>
          </div>
          <div v-if="curHotFeature === 6" key="6" class="feature-box">
            <img v-if="lang === 'en'" class="feature-right" v-lazy="require('../assets/imgs/en/bg09@2x.png')" alt="">
            <img v-else  class="feature-right" v-lazy="require('../assets/imgs/zh/bg09@2x.png')" alt="">
            <div class="feature-left">
              <div class="feature-title">{{$t('message.englishTextThirty')}}</div>
              <div class="feature-description">{{$t('message.advanceToolsDesc01')}}</div>
              <div class="feature-description">{{$t('message.advancedToolsDesc02')}}</div>
              <div class="feature-description">{{$t('message.advancedToolsDesc03')}}</div>
              <div class="feature-description">{{$t('message.advancedToolsDesc04')}}</div>
              <div class="feature-link" @click="gotoBlank('https://tools.greeks.live')"><span class="link">tools.greeks.live</span></div>
            </div>
          </div>
        </transition>
      </div>
      <div class="set-index bottom-box">
        <div class="logo-box">
          <img class="logo-img" src="../assets/imgs/logo.png" alt="">
          <span class="logo-text">GREEKS.LIVE</span>
        </div>
        <div class="contact-box">
          <div style="font-size: 10px;color: #AEAEAE;cursor: pointer;" @click="$router.push('/terms_of_service')">{{$t('message.englishTextThirtyOne')}}</div>
          <div class="contact-btn-box">
            <div class="contact-btn" @click="gotoBlank('https://twitter.com/greekslive?s=11&t=8gbqBNwYvjWYE3nM-8r9XQ')">
              <img class="selected" style="width: 100%;height: 100%;" src="../assets/imgs/contact_selected01@2x.png" alt="">
              <img class="unselect" style="width: 100%;height: 100%;" src="../assets/imgs/contact_unselect01@2x.png" alt="">
            </div>
            <div class="contact-btn" @click="joinTg">
              <img class="selected" style="width: 100%;height: 100%;" src="../assets/imgs/contact_selected02@2x.png" alt="">
              <img class="unselect" style="width: 100%;height: 100%;" src="../assets/imgs/contact_unselect02@2x.png" alt="">
            </div>
            <div class="contact-btn" @click="sendEmail">
              <img class="selected" style="width: 100%;height: 100%;" src="../assets/imgs/contact_selected04@2x.png" alt="">
              <img class="unselect" style="width: 100%;height: 100%;" src="../assets/imgs/contact_unselect04@2x.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>

    <notification ref="notification" placement="topRight" :duration="2000" :top="60"></notification>
  </div>
</template>

<script>
  import {isPC} from "@/utils/utils";
  import Notification from "@/views/components/Notification.vue";

  export default {
    name: "Home",
    components: {Notification},
    data() {
      return {
        curProduct: 'web',
        lang: 'en',
        count: 4,
        safeRunningDays: 0,
        timer: null,
        timer2: null,
        timer3: null,
        isPC: true,
        curHotFeature: 1,
        showNav: false,
        pressTimer: null,
      }
    },
    created() {
      this.lang = localStorage.getItem('lang') || 'en'
      this.$i18n.locale = this.lang
      this.isPC = isPC()
      this.documentInit()

      window.onresize = () => {
        this.isPC = isPC()
        this.documentInit()
      }
    },
    beforeDestroy() {
      this.clearTimer()
      this.clearTimer2()
      this.clearTimer3()
    },
    methods: {
      touchstart(system, type) {
        if (this.pressTimer) {
          clearTimeout(this.pressTimer)
          this.pressTimer = null
        }
        this.pressTimer = setTimeout(() => {
          let url = this.returnDownloadUrl(system, type)
          this.copyToClipboard(url)
        }, 600)
      },
      touchend() {
        this.pressTimer && clearTimeout(this.pressTimer)
      },
      copyToClipboard(text) {
        navigator.clipboard.writeText(text).then(() => {
          const notification = {
            title: this.$t('message.tips'),
            description: this.$t('message.copySuccess')
          }
          this.$refs.notification.success(notification) // success调用
        }).catch(err => {
        });
      },
      handleRightClick(system, type) {
        let url = this.returnDownloadUrl(system, type)
        this.copyToClipboard(url)
      },
      gotoDownloadAppPage() {
        window.open('https://about.greeks.live/#/download/basic_trade','_self')
      },
      gotoTrade(exchange) {
        switch (exchange) {
          case 'deribit':
            window.open('https://www.greeks.live/#/deribit/options','_blank')
            break
          case 'bybit':
            window.open('https://www.greeks.live/#/bybit/options','_blank')
            break
          case 'okx':
            window.open('https://www.greeks.live/#/okx/options','_blank')
            break
          case 'bit':
            window.open('https://www.greeks.live/#/bit/options','_blank')
            break
        }
      },
      toggleNav() {
        this.showNav = !this.showNav
      },
      clearTimer3() {
        //  清理定时器
        if (this.timer3) {
          clearInterval(this.timer3)
          this.timer3 = null
        }
      },
      slide3() {
        this.timer3 = setInterval(() => {
          this.curHotFeature += 1
          if (this.curHotFeature > 6) {
            this.curHotFeature = 1
          }
        },3000)
      },
      changeHotFeature(flag) {
        if (this.curHotFeature >= 6 && flag) {
          return
        }
        if (this.curHotFeature  <= 1 && !flag) {
          return
        }

        if (flag) {
          this.curHotFeature += 1
        } else {
          this.curHotFeature -= 1
        }
      },
      documentInit() {
        this.clearTimer()
        this.clearTimer2()
        this.clearTimer3()
        this.setSafeRunningDays()
        if (this.isPC) {
          this.slide()
          this.slide2()
        } else {
          this.slide3()
        }
      },
      sendEmail() {
        window.location.href = "mailto:support@greeks.live"
      },
      clearTimer() {
        //  清理定时器
        if (this.timer) {
          clearInterval(this.timer)
          this.timer = null
        }
      },
      slide() {
        const arr = ['web', 'blocktrade','socialTrading','advancedTradingTools','webOld']
        this.timer = setInterval(() => {
          let index = arr.indexOf(this.curProduct)
          if (index >= arr.length - 1) {
            index = 0
          } else {
            index++
          }
          this.curProduct = arr[index]
        },3000)
      },
      clearTimer2() {
        //  清理定时器
        if (this.timer2) {
          clearInterval(this.timer2)
          this.timer2 = null
        }
      },
      slide2() {
        this.timer2 = setInterval(() => {
          this.count = this.count + 1
          if (this.count >= 8) {
            this.count = 4
          }
          let offset = this.count * -280
          this.$refs.slide.style.transform = `translate3d(${offset}px,0,0)`
        },3000)
      },
      returnDownloadUrl(system, type) {
        let url = ''
        if (system === 'ios') {
          switch (type) {
            case "web":
              url = 'https://testflight.apple.com/join/QGPOEUBM'
              break
            case "blocktrade":
              url = 'https://testflight.apple.com/join/bjKTB8X6'
              break
            case "socialTrading":
              url = 'https://testflight.apple.com/join/9MQQeR0l'
              break
            case "webOld":
              url = 'https://testflight.apple.com/join/XtGNslcZ'
              break
          }
        } else {
          switch (type) {
            case "web":
              url = 'https://cdn.greeks.live/app/Greeks_Live.apk'
              break
            case "blocktrade":
              url = 'https://cdn.greeks.live/app/Greeks_Live_Block_Trade.apk'
              break
            case "socialTrading":
              url = 'https://about.greeks.live/download/app/Greeks.Cafe.apk'
              break
            case "webOld":
              url = 'https://about.greeks.live/download/app/Greeks.live-Legacy.apk'
              break
          }
        }

        return url
      },
      downloadApp(system, type) {
        let url = this.returnDownloadUrl(system, type)
        window.open(url,'_blank')
      },
      setSafeRunningDays() {
        this.safeRunningDays = parseInt(Math.abs(new Date().getTime() - new Date('2020-04-23').getTime()) / (1000 * 60 * 60 * 24));
        setInterval(() => {
          this.safeRunningDays = parseInt(Math.abs(new Date().getTime() - new Date('2020-04-23').getTime()) / (1000 * 60 * 60 * 24));
        },60000)
      },
      prev() {
        this.count--
        if (this.count <= 0) {
          this.count = 4
        }
        let offset = this.count * -280
        this.$refs.slide.style.transform = `translate3d(${offset}px,0,0)`
      },
      next() {
        this.count++
        if (this.count >= 8) {
          this.count = 4
        }
        let offset = this.count * -280
        this.$refs.slide.style.transform = `translate3d(${offset}px,0,0)`
      },
      toggleLang(lang) {
        this.lang = lang
        this.$i18n.locale = lang
        localStorage.setItem('lang', lang)
      },
      gotoBlank(url) {
        window.open(url,'_blank')
      },

      joinTg() {
        if (this.lang === 'en') {
          this.gotoBlank('https://t.me/greekslive')
        } else {
          this.gotoBlank('https://t.me/greekslivecn')
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .home-pc {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: black;
    color: white;
    user-select: none;
    overflow: hidden;
    padding-bottom: 20px;
    .nav-bar {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 999999;
      box-sizing: border-box;
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      background: #060D0C;
      .left {
        height: 100%;
        display: flex;
        align-items: center;
        .nav-item-box {
          display: flex;
          align-items: center;
          margin-left: 32px;
          height: 100%;
          .nav-item {
            height: 100%;
            position: relative;
            display: flex;
            align-items: center;
            margin-left: 32px;
            padding-right: 20px;
            .text {
              color: white;
            }
            .arrow {
              position: absolute;
              top: 23px;
              right: 4px;
              width: 0;
              height: 0;
              border: 4px solid;
              border-color: #ffffff transparent transparent transparent;
            }
          }
        }
      }
      .right {
        height: 100%;
        display: flex;
        align-items: center;
        .download-app-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 8px;
          height: 30px;
          border-radius: 8px 8px 8px 8px;
          border: 1px solid #26AF9B;
          margin-right: 16px;
          cursor: pointer;
          background: #26AF9B;
        }
        .btn {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 8px;
          height: 30px;
          border-radius: 8px 8px 8px 8px;
          border: 1px solid #66F8E4;
          margin-right: 16px;
          cursor: pointer;
        }
      }
    }
    .section01 {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 584px;
      background: url("../assets/imgs/navBg.png") repeat;
      background-size: cover;
      .section-content {
        max-width: 1379px;
        width: 95%;
        margin-top: 66px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title-box {
          .title {
            font-size: 50px;
            line-height: 59px;
            font-weight: 800;
            margin-bottom: 17px;
          }
          .sub-title {
            line-height: 28px;
            margin-bottom: 20px;
          }
          .exchange-box {
            display: flex;
            margin-bottom: 30px;
            .exchange {
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 120px;
              height: 40px;
              border: 1px solid #FFFFFF;
              margin-right: 30px;
              &:last-child {
                margin: 0;
              }
            }
          }
        }
        .right-bg {
          width: 590px;
          height: 351px;
        }
      }
      .join-tg {
        position: relative;
        display: inline-block;
        height: 40px;
        line-height: 40px;
        text-align: center;
        padding: 0 20px 0 50px;
        background: #4DA8EF;
        border-radius: 22px 22px 22px 22px;
        cursor: pointer;
      }
    }
    .section-title {
      text-align: center;
      font-size: 30px;
      font-weight: 600;
      line-height: 35px;
    }
    .production-list {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 75px;
      .production {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        padding: 0 16px;
        font-size: 18px;
        margin-right: 84px;
        font-weight: bold;
        cursor: pointer;
        &.selected {
          color: black;
          border-radius: 22px 22px 22px 22px;
          background: #66F8E4;
        }
      }
    }
    .production-content {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 105px;
      .left-production-img {
        margin-right: 160px;
        width: 580px;
        height: 330px;
      }
      .download-box {
        width: 280px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .qr-code {
          width: 80px;
          height: 80px;
        }
      }
      .ddh-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 280px;
        .ddh-title {
          height: 30px;
          font-size: 18px;
          font-weight: bold;
          color: #FFFFFF;
        }
        .ddh-description {
          font-size: 14px;
          color: #FFFFFF;
          line-height: 30px;
        }
      }
    }
    .orientation-list {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 100px;
      .orientation-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 154px;
        &:last-child {
          margin: 0;
        }
        .orientation-desc {
          margin-top: 41px;
          font-size: 18px;
        }
      }
    }
    .why-options-list-wrapper {
      margin-bottom: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      .left-arrow {
        width: 20px;
        height: 20px;
        background-color: transparent; /* 模块背景为透明 */
        border-color: #ffffff;
        border-style: solid;
        border-width: 0 0 2px 2px;
        transform: rotate(45deg); /*箭头方向可以自由切换角度*/
        cursor: pointer;
      }
      .right-arrow {
        width: 20px;
        height: 20px;
        background-color: transparent; /* 模块背景为透明 */
        border-color: #ffffff;
        border-style: solid;
        border-width: 2px 2px 0 0;
        transform: rotate(45deg); /*箭头方向可以自由切换角度*/
        cursor: pointer;
      }
      .slider-box {
        position: relative;
        margin: 0 40px;
        width: 1090px;
        height: 180px;
        overflow: hidden;
        .why-options-list {
          position: absolute;
          top: 0;
          left: 0;
          transform: translate3d(-1120px,0,0);
          height: 100%;
          width: 3330px;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.3s ease;
          .why-options-item {
            position: relative;
            width: 250px;
            height: 100%;
            border-radius: 12px 12px 12px 12px;
            margin-right: 30px;
            font-weight: 600;
            &:last-child {
              margin: 0;
            }
          }
        }
      }
    }
    .feature-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .feature-box {
        width: 1180px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 100px;
        .feature-left {
          width: 430px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-right: 30px;
          .feature-title {
            font-size: 30px;
            line-height: 35px;
            font-weight: 800;
            margin-bottom: 12px;
          }
          .feature-description {
            position: relative;
            font-size: 24px;
            color: #D9D9D9;
            line-height: 28px;
            padding-left: 20px;
            margin-bottom: 12px;
            &::before {
              position: absolute;
              top: 50%;
              left: 0;
              transform: translate3d(0,-50%,0);
              content: "";
              display: inline-block;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background-color: #ffffff;
            }
          }
          .feature-link {
            display: inline;
            font-size: 24px;
            font-weight: 600;
            color: #66F8E4;
            cursor: pointer;
            .link {
              border-bottom: 1px solid #66F8E4;
            }
          }
          .learn-link {
            display: inline;
            font-size: 16px;
            font-weight: 400;
            cursor: pointer;
            margin-top: 20px;
            color: #D9D9D9;
            .link {
              border-bottom: 1px solid #D9D9D9!important;
            }
          }
        }
        .feature-right {
          width: 674px;
          height: 389px;
        }
      }
    }
    .running-time-box {
      max-width: 1379px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 100px;
      .running-box {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .running-label {
          font-size: 18px;
          color: #FFFFFF;
          margin-bottom: 30px;
        }
        .running-data {
          font-size: 56px;
          font-weight: bold;
          color: #66F8E4;
        }
      }
      .divide-line {
        height: 80px;
        width: 1px;
        background: #ffffff;
      }
    }
    .partner-box {
      width: 1071px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 90px;
      .partner-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &:last-child {
          margin: 0;
        }
        .partner-name {
          margin-top: 24px;
          font-size: 20px;
          font-weight: 600;
          color: #FFFFFF;
        }
      }
    }
    .bottom-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 1168px;
      .contact-box {
        display: flex;
        justify-content: center;
        align-items: center;
        .contact-btn-box {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 21px;
          .contact-btn {
            width: 24px;
            height: 24px;
            margin-right: 24px;
            cursor: pointer;
            &:last-child {
              margin: 0;
            }
            &:hover {
              .selected {
                display: block;
              }
              .unselect {
                display: none;
              }
            }
            .selected {
              display: none;
            }
            .unselect {
              display: block;
            }
          }
        }
      }
    }
    .logo-box {
      display: flex;
      align-items: center;
      .logo-img {
        width: 59px;
        height: 50px;
      }
      .logo-text {
        font-size: 20px;
        font-weight: 800;
      }
    }
    .dropdown {
      position: relative;
      display: flex;
      align-items: center;
      &:hover {
        .dropdown-menu {
          display: block;
        }
        .dropdown-menu-right {
          display: block;
        }
      }
      .dropdown-menu {
        padding: 10px 0;
        border-radius: 4px;
        display: none;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate3d(-50%,-1px,0);
        background: white;
        color: black;
        &::before {
          position: absolute;
          top: -3px;
          left: 50%;
          transform: translate3d(-50%,0,0) rotateZ(45deg);
          content: "";
          display: inline-block;
          width: 6px;
          height: 6px;
          background-color: #ffffff;
        }
        .dropdown-menu-item {
          padding: 10px 20px;
          cursor: pointer;
          font-size: 14px;
          &:hover {
            background: #e9f7f5;
          }
        }
      }
      .dropdown-menu-right {
        padding: 10px 0;
        border-radius: 4px;
        display: none;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate3d(-90%,-1px,0);
        background: white;
        color: black;
        &::before {
          position: absolute;
          top: -3px;
          right: 10px!important;
          transform: translate3d(0,0,0) rotateZ(45deg);
          content: "";
          display: inline-block;
          width: 6px;
          height: 6px;
          background-color: #ffffff;
        }
        .dropdown-menu-item {
          padding: 10px 20px;
          cursor: pointer;
          font-size: 14px;
          &:hover {
            background: #e9f7f5;
          }
        }
      }
    }
    .lang-selected {
      color: #66F8E4;
    }
    .circle-bg01 {
      position: absolute;
      top: 653px;
      left: -842px;
      z-index: 1;
      width: 1329px;
      height: 1329px;
      border-radius: 50%;
      filter: blur(524px) opacity(0.8);
      background: #2A4C8A;
    }
    .circle-bg02 {
      position: absolute;
      top: 1488px;
      right: -842px;
      z-index: 1;
      width: 1329px;
      height: 1329px;
      border-radius: 50%;
      filter: blur(524px) opacity(0.8);
      background: #198576;
    }
    .circle-bg03 {
      position: absolute;
      top: 2643px;
      left: -672px;
      z-index: 1;
      width: 1329px;
      height: 1329px;
      border-radius: 50%;
      filter: blur(524px) opacity(0.8);
      background: #1B6B64;
    }
    .circle-bg04 {
      position: absolute;
      top: 4166px;
      right: -672px;
      z-index: 1;
      width: 1329px;
      height: 1329px;
      border-radius: 50%;
      filter: blur(524px) opacity(0.8);
      background: #6B1B63;
    }
    .set-index {
      position: relative;
      z-index: 9;
    }
    .download-btn {
      margin-top: 17px;
      display: flex;
      align-items: center;
      width: 155px;
      height: 39px;
      border: 1px solid #FFFFFF;
      border-radius: 4px;
      cursor: pointer;
      &.to-block-btn {
        background: #66F8E4;
        border-color: #66F8E4;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-content: center;
      }
    }
  }




  .home-mobile {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: black;
    color: white;
    user-select: none;
    overflow: hidden;
    padding-bottom: 40px;
    .circle-bg01 {
      position: absolute;
      top: 653px;
      left: -842px;
      z-index: 1;
      width: 1329px;
      height: 1329px;
      border-radius: 50%;
      filter: blur(524px) opacity(0.8);
      background: #2A4C8A;
    }
    .circle-bg02 {
      position: absolute;
      top: 1488px;
      right: -842px;
      z-index: 1;
      width: 1329px;
      height: 1329px;
      border-radius: 50%;
      filter: blur(524px) opacity(0.8);
      background: #198576;
    }
    .circle-bg03 {
      position: absolute;
      top: 2643px;
      left: -672px;
      z-index: 1;
      width: 1329px;
      height: 1329px;
      border-radius: 50%;
      filter: blur(524px) opacity(0.8);
      background: #1B6B64;
    }
    .circle-bg04 {
      position: absolute;
      top: 4166px;
      right: -672px;
      z-index: 1;
      width: 1329px;
      height: 1329px;
      border-radius: 50%;
      filter: blur(524px) opacity(0.8);
      background: #6B1B63;
    }
    .nav-bar {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 999999;
      box-sizing: border-box;
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
      background: #060D0C;
      .dropdown-nav-box {
        position: fixed;
        top: 49px;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        background: rgba(0,0,0,0.8);
        .dropdown-nav-item {
          height: 38px;
          line-height: 38px;
          font-size: 14px;
          padding-left: 20px;
        }
      }
      .left {
        height: 100%;
        display: flex;
        align-items: center;
        .nav-item-box {
          display: flex;
          align-items: center;
          margin-left: 32px;
          height: 100%;
          .nav-item {
            height: 100%;
            position: relative;
            display: flex;
            align-items: center;
            margin-left: 32px;
            padding-right: 20px;
            .text {
              color: white;
            }
            .arrow {
              position: absolute;
              top: 23px;
              right: 4px;
              width: 0;
              height: 0;
              border: 4px solid;
              border-color: #ffffff transparent transparent transparent;
            }
          }
        }
      }
      .right {
        height: 100%;
        display: flex;
        align-items: center;
        .download-app-btn {
          font-size: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 8px;
          height: 24px;
          border-radius: 8px 8px 8px 8px;
          border: 1px solid #26AF9B;
          margin-right: 16px;
          cursor: pointer;
          background: #26AF9B;
        }
        .btn {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 8px;
          height: 30px;
          border-radius: 8px 8px 8px 8px;
          border: 1px solid #66F8E4;
          margin-right: 16px;
          cursor: pointer;
        }
      }
    }
    .section01 {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      background: url("../assets/imgs/navBg.png") repeat;
      background-size: cover;
      padding-bottom: 154px;
      .section-content {
        max-width: 1379px;
        width: 95%;
        margin-top: 150px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .title-box {
          text-align: center;
          .title {
            font-size: 24px;
            font-weight: 800;
            margin-bottom: 17px;
          }
          .sub-title {
            font-size: 12px;
            margin-bottom: 20px;
          }
          .exchange-box {
            display: flex;
            justify-content: center;
            margin-bottom: 30px;
            .exchange {
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 60px;
              height: 20px;
              border: 1px solid #FFFFFF;
              margin-right: 30px;
              &:last-child {
                margin: 0;
              }
            }
          }
        }
        .right-bg {
          width: 295px;
          height: 175px;
        }
      }
      .join-tg {
        position: relative;
        font-size: 11px;
        height: 20px;
        padding: 0 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #4DA8EF;
        border-radius: 22px 22px 22px 22px;
        cursor: pointer;
        margin-bottom: 46px;
      }
    }
    .section-title {
      text-align: center;
      font-size: 18px;
      line-height: 25px;
      font-weight: 600;
    }
    .production-row {
      width: 100%;
      display: flex;
      .production-item {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .left-production-img {
          width: 145px;
          height: 82px;
        }
      }
    }
    .orientation-list {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 100px;
      .orientation-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 154px;
        &:last-child {
          margin: 0;
        }
        .orientation-desc {
          margin-top: 41px;
          font-size: 18px;
        }
      }
    }
    .why-options-list {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      .why-options-item {
        position: relative;
        width: 150px;
        height: 108px;
        font-size: 10px;
      }
    }
    .feature-wrapper {
      font-size: 15px;
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .toggle-feature-btn-box {
        position: absolute;
        bottom: 20px;
        right: 20px;
      }
      .feature-box {
        width: 318px;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 520px;
        .feature-left {
          margin-top: 30px;
          display: inline-block;
          .feature-title {
            font-size: 15px;
            line-height: 21px;
            font-weight: 800;
            margin-bottom: 12px;
          }
          .feature-description {
            position: relative;
            font-size: 12px;
            color: #D9D9D9;
            line-height: 17px;
            padding-left: 20px;
            margin-bottom: 12px;
            &::before {
              position: absolute;
              top: 50%;
              left: 0;
              transform: translate3d(0,-50%,0);
              content: "";
              display: inline-block;
              width: 5px;
              height: 5px;
              border-radius: 50%;
              background-color: #ffffff;
            }
          }
          .feature-link {
            display: inline;
            font-size: 12px;
            font-weight: 600;
            color: #66F8E4;
            cursor: pointer;
            .link {
              border-bottom: 1px solid #66F8E4;
            }
          }
          .learn-link {
            display: inline;
            font-size: 10px;
            font-weight: 400;
            cursor: pointer;
            padding-top: 10px;
            color: #D9D9D9;
            .link {
              border-bottom: 1px solid #D9D9D9!important;
            }
          }
        }
        .feature-right {
          width: 319px;
          height: 175px;
        }
      }
    }
    .running-time-box {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 155px;
      .running-box {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .running-label {
          font-size: 9px;
          color: #FFFFFF;
          margin-bottom: 16px;
        }
        .running-data {
          font-size: 28px;
          font-weight: bold;
          color: #66F8E4;
        }
      }
      .divide-line {
        height: 1px;
        width: 80px;
        margin: 30px 0;
        background: #ffffff;
      }
    }
    .partner-box {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .partner-item {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .partner-name {
          margin-top: 12px;
          font-size: 10px;
          line-height: 14px;
          font-weight: 600;
          color: #FFFFFF;
        }
      }
    }
    .bottom-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding:  0 15px 0 5px;
      box-sizing: border-box;
      .contact-box {
        display: flex;
        justify-content: center;
        align-items: center;
        .contact-btn-box {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 21px;
          .contact-btn {
            width: 15px;
            height: 15px;
            margin-right: 12px;
            cursor: pointer;
            &:last-child {
              margin: 0;
            }
            &:hover {
              .selected {
                display: block;
              }
              .unselect {
                display: none;
              }
            }
            .selected {
              display: none;
            }
            .unselect {
              display: block;
            }
          }
        }
      }
    }
    .logo-box {
      display: flex;
      align-items: center;
      .logo-img {
        width: 48px;
        height: 40px;
      }
      .logo-text {
        font-size: 16px;
        font-weight: 600;
      }
    }
    .dropdown {
      &:hover {
        .dropdown-menu {
          display: block;
        }
        .dropdown-menu-right {
          display: block;
        }
      }
    }
    .dropdown1 {
      position: relative;
      display: flex;
      align-items: center;
      .dropdown-menu {
        padding: 10px 0;
        border-radius: 4px;
        display: none;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate3d(-50%,-1px,0);
        background: white;
        color: black;
        &::before {
          position: absolute;
          top: -3px;
          left: 50%;
          transform: translate3d(-50%,0,0) rotateZ(45deg);
          content: "";
          display: inline-block;
          width: 6px;
          height: 6px;
          background-color: #ffffff;
        }
        .dropdown-menu-item {
          padding: 10px 20px;
          cursor: pointer;
          font-size: 14px;
          &:hover {
            background: #e9f7f5;
          }
        }
      }
      .dropdown-menu-right {
        padding: 10px 0;
        border-radius: 4px;
        display: none;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate3d(-90%,-1px,0);
        background: white;
        color: black;
        &::before {
          position: absolute;
          top: -3px;
          right: 10px!important;
          transform: translate3d(0,0,0) rotateZ(45deg);
          content: "";
          display: inline-block;
          width: 6px;
          height: 6px;
          background-color: #ffffff;
        }
        .dropdown-menu-item {
          padding: 10px 20px;
          cursor: pointer;
          font-size: 14px;
          &:hover {
            background: #e9f7f5;
          }
        }
      }
    }
    .lang-selected {
      color: #66F8E4;
    }
    .circle-bg01 {
      position: absolute;
      left: 50%;
      top: 0;
      z-index: 1;
      width: 700px;
      height: 700px;
      border-radius: 50%;
      filter: blur(524px) opacity(0.8);
      background: #2A4C8A;
    }
    .circle-bg02 {
      position: absolute;
      right: 50%;
      top: 700px;
      z-index: 1;
      width: 700px;
      height: 700px;
      border-radius: 50%;
      filter: blur(524px) opacity(0.8);
      background: #198576;
    }
    .circle-bg03 {
      position: absolute;
      left: 50%;
      top: 1400px;
      z-index: 1;
      width: 700px;
      height: 700px;
      border-radius: 50%;
      filter: blur(524px) opacity(0.8);
      background: #1B6B64;
    }
    .circle-bg04 {
      position: absolute;
      right: 50%;
      top: 2100px;
      z-index: 1;
      width: 700px;
      height: 700px;
      border-radius: 50%;
      filter: blur(524px) opacity(0.8);
      background: #1B6B64;
    }
    .circle-bg05 {
      position: absolute;
      left: 50%;
      top: 2800px;
      z-index: 1;
      width: 700px;
      height: 700px;
      border-radius: 50%;
      filter: blur(524px) opacity(0.8);
      background: #6B1B63;
    }
    .set-index {
      position: relative;
      z-index: 9;
    }
    .download-btn {
      margin-top: 9px;
      display: flex;
      align-items: center;
      width: 92px;
      height: 19px;
      border: 1px solid #FFFFFF;
      border-radius: 4px;
      cursor: pointer;
    }
    .to-block-btn {
      margin-top: 15px;
      display: inline-block;
      color: #66F8E4;
      font-size: 12px;
      border-bottom: 1px solid #66F8E4;
      cursor: pointer;
    }
  }

  .slide-fade-enter-active,
  .slide-fade-leave-active {
    transition: all 0.5s ease-in-out;
  }

  .slide-fade-enter-from,
  .slide-fade-leave-to {
    transform: translateX(100%);
    opacity: 0;
  }
</style>
