<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>

  export default {
    name: "DownloadCafe",
    created() {
      document.title = `Greeks.live`
    }
  }

</script>

<style lang="scss">
  #app {
    width: 100vw;
    min-height: 100vh;
    background: #000000;
  }
</style>
