const message = {
  englishTextOne: "专业期权交易者的工具箱",
  englishTextTwo: "首页",
  englishTextThree: "大宗交易",
  englishTextFour: "新手教程",
  englishTextFive: "联系我们",
  englishTextSix: "推特",
  englishTextSeven: "电报 (中文)",
  englishTextEight: "电报 (英文)",
  englishTextNine: "关于我们",
  englishTextTen: "大师计划",
  englishTextEleven: "连接",
  englishTextTwelve: "交易网站",
  englishTextThirteen: "一键对冲",
  englishTextFourteen: "一键追单",
  englishTextFifteen: "订单排序",
  AutoLendingTool: "自动借贷工具",
  englishTextSixteen: "自定义列",
  englishTextSeventeen: "头寸可视化",
  englishTextEighteen: "期权结构、成交量、持仓悬浮窗口",
  englishTextNineteen: "行情回放",
  englishTextTwenty: "子账号",
  englishTextTwentyOne: "期权简选",
  englishTextTwentyTwo: "头寸可视化",
  englishTextTwentyFour: "数据实验室",
  englishTextTwentyFive: "高级自动 DDH，限价单",
  englishTextTwentySix: "自动合成交割",
  englishTextTwentySeven: "合成敲入期权",
  englishTextTwentyEight: "期货互换",
  dataLabDesc06: "最大痛点",
  englishTextTwentyNine: "行情回放",
  playBackDesc01: "回放最近的期权市场活动",
  playBackDesc02: "回放IV是如何逐步变化的",
  englishTextThirty: "高阶交易工具",
  englishTextThirty02: "高阶交易工具",
  advanceToolsDesc01: "动态对冲",
  advancedToolsDesc02: "挂单助手",
  advancedToolsDesc03: "期货互换",
  advancedToolsDesc04: "合成交割",
  englishTextThirtyOne: "服务条款",
  englishTextThirtyTwo: "Deribit",
  englishTextThirtyThree: "BIT",
  trade: '交易',
  baseTrade: '基础交易',
  socialTrading: '实盘系统',
  joinTg: '加入我们的期权交易社区',
  relatedProducts: '相关产品',
  whyOptions: '为什么选择加密期权？',
  whyOptionsDesc01: '超级高杠杆率高达 2000 倍，没有强制平仓的风险（仅限标准保证金买入期权）',
  whyOptionsDesc02: '在保留上涨潜力的同时有效对冲投资组合；',
  whyOptionsDesc03: '即使在熊市也能获得最佳收益；',
  whyOptionsDesc04: '波动率的新交易维度。跨式期权、宽跨式期权、蝶式期权和带有主动 Delta 对冲的期权交易；',
  feature: '最新热门功能',
  startDate: '项目开始日期',
  runningTime: '至今安全运行',
  days: '天',
  backed: '最好的支持',
  subtitle: '同步四个主要交易所账户，让您的投资更加方便',
  officialWebsite: '前往官网',
  lengTools: '借贷工具',
  lengToolsDesc01: '多种加密货币选择',
  lengToolsDesc02: '灵活的借贷期限',
  lengToolsDesc03: '自动匹配借贷交易',
  lengToolsDesc04: '高安全性和透明度',
  lengToolsDesc05: '良好的流动性和市场深度',
  userProtocolTitle: '用户协议',
  termsOfServiceAutoDDHContentL: '<p>为了使用网页交易、高阶交易工具、灵动策略和实盘展示功能，您同意并授权我们的服务器读取您的仓位信息并进行数据处理和交易执行。</p><p>本应用提供给您一个管理头寸组合的工具。您应评估后独立承担风险使用。您了解并同意本应用有高度的财务风险，您有足够的经验和知识了解本应用的风险。</p><p>我们会尽力提供一款运行顺利的应用。在我们认为需要时，会尽快修复错误和缺陷并对应用进行升级。我们不对任何错误或缺陷造成的后果承担责任。使用本应用带来的任何风险、损失或伤害都由您独自承担。</p><p>在数字货币交易所宕机或维护时，我们无法向交易所传递交易信号。这个风险由您独自承担。</p>',
  termsOfServiceAPIKeyContentL: '<p>当您使用API密钥时，请确保其被妥善存放和传输。暴露您的密钥会导致您的账户被盗。以下为推荐的最优做法：</p><li>我们推荐您使用IP白名单，这样可以降低泄露密钥的影响。</li><li>删除不需要的API密钥，从而降低被攻击的敞口。</li><li>定期重置您的API密钥。</li><p>请注意：Greeks.live及其应用不存放您任何API密钥信息。您将是安全使用您API密钥的单一责任人。</p>',
  advanceToolsTips: '提供了更大的灵活性和风险管理能力。',
  learnMoreDatalab: '了解更多关于Greeks.live数据实验室',
  learnMorePlayback: '了解更多关于Greeks.live行情回放',
  downloadApp: '下载APP',
  tips: '提示',
  copySuccess: '复制下载链接成功',
  download: '下载',
  openInBrowser: '请使用浏览器打开',
  oldVer2: '旧版本',
}


const zh = {
  message: message,
}

export default zh
