// 生成唯一的UUID
export const generateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    let r = Math.random()*16|0, v = c === 'x' ? r : (r&0x3|0x8);
    return v.toString(16);
  })
}

export const getSystem = () => {
  var u = navigator.userAgent
  var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1;
  var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) || /iPhone|iPad|iPod|iOS/.test(u)
  var isMac = (navigator.platform == "Mac68K") || (navigator.platform == "MacPPC") || (navigator.platform == "Macintosh") || (navigator.platform == "MacIntel");
  if (isIOS || isMac) {
    return "ios"
  }
  if (isAndroid) {
    return "android"
  }
  return 'other'
}

export const upFixed = (num, fix) => {
  let res
  if (fix > 0) {
    const pow = Math.pow(10, fix)
    res = Math.ceil(num * pow)
    if (res % 2 === 1) {
      res++
    }
    res = res / pow
  } else {
    res = Math.ceil(num)
    if (res % 2 === 1) {
      res++
    }
  }
  return res
}

//判断是否是pc端 true为pc端 false为移动端
export const isPC = () => {
  return !(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent))
}

// 返回一个数的类型
export const returnType = (arg) => {
  return Object.prototype.toString.call(arg)
}

// 复制一段文字,需要改变this指向
export const copyText = (text, that) => {
  // 动态创建 textarea 标签
  const textarea = document.createElement('textarea');
  // 将该 textarea 设为 readonly 防止 iOS 下自动唤起键盘，同时将 textarea 移出可视区域
  textarea.readOnly = 'readonly';
  textarea.style.position = 'absolute';
  textarea.style.left = '-9999px';
  // 将要 copy 的值赋给 textarea 标签的 value 属性
  textarea.value = text;
  // 将 textarea 插入到 body 中
  document.body.appendChild(textarea);
  // 选中值并复制
  textarea.select();
  textarea.setSelectionRange(0, textarea.value.length);
  const result = document.execCommand('Copy');
  if (result) {
    that.$toast.success(that.$t('copySuccess'))
  }
  document.body.removeChild(textarea);
}

// 判断是否使用微信打开
export const isWX = () => {
  const u = navigator.userAgent, app = navigator.appVersion;
  //是否是微信
  return u.indexOf("MicroMessenger") > 0
}

// 获取当前浏览器使用的语言
export function getSystemLang() {
  let jsSrc = (navigator.language || navigator.browserLanguage).toLowerCase()
  if(jsSrc.indexOf('zh') !== -1) {
    return 'zh'
  } else {
    return 'en'
  }
}
