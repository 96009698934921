const message = {
  englishTextOne: "專業期權交易者的工具箱",
  englishTextTwo: "首頁",
  englishTextThree: "大宗交易",
  englishTextFour: "新手教程",
  englishTextFive: "聯繫我們",
  englishTextSix: "推特",
  englishTextSeven: "電報 (中文)",
  englishTextEight: "電報 (英文)",
  englishTextNine: "關於我們",
  englishTextTen: "大師計劃",
  englishTextEleven: "連接",
  englishTextTwelve: "交易網站",
  englishTextThirteen: "一鍵對沖",
  englishTextFourteen: "一鍵追單",
  englishTextFifteen: "訂單排序",
  AutoLendingTool: "自動借貸工具",
  englishTextSixteen: "自定義列",
  englishTextSeventeen: "頭寸可視化",
  englishTextEighteen: "期權結構、成交量、持倉懸浮窗口",
  englishTextNineteen: "行情回放",
  englishTextTwenty: "子賬號",
  englishTextTwentyOne: "期權簡選",
  englishTextTwentyTwo: "頭寸可視化",
  englishTextTwentyFour: "數據實驗室",
  englishTextTwentyFive: "高級自動 DDH，限價單",
  englishTextTwentySix: "自動合成交割",
  englishTextTwentySeven: "合成敲入期權",
  englishTextTwentyEight: "期貨互換",
  englishTextTwentyNine: "行情回放",
  playBackDesc01: "回放最近的期權市場活動",
  playBackDesc02: "回放IV是如何逐步變化的",
  englishTextThirty: "高階交易工具",
  englishTextThirty02: "高階交易工具",
  advanceToolsDesc01: "動態對沖",
  advancedToolsDesc02: "掛單助手",
  advancedToolsDesc03: "期貨互換",
  advancedToolsDesc04: "合成交割",
  englishTextThirtyOne: "服務條款",
  englishTextThirtyTwo: "Deribit",
  englishTextThirtyThree: "BIT",
  trade: '交易',
  baseTrade: '基礎交易',
  socialTrading: '實盤系統',
  joinTg: '加入我們的期權交易社區',
  relatedProducts: '相關產品',
  whyOptions: '為什麼選擇加密期權？',
  whyOptionsDesc01: '超級高槓杆率高達 2000 倍，沒有強制平倉的風險（僅限標準保證金買入期權）',
  whyOptionsDesc02: '在保留上漲潛力的同時有效對沖投資組合；',
  whyOptionsDesc03: '即使在熊市也能獲得最佳收益；',
  whyOptionsDesc04: '波動率的新交易維度。跨式期權、寬跨式期權、蝶式期權和帶有主動 Delta 對沖的期權交易；',
  feature: '最新熱門功能',
  startDate: '項目開始日期',
  runningTime: '至今安全運行',
  days: '天',
  backed: '最好的支持',
  subtitle: '同步四個主要交易所賬户，讓您的投資更加方便',
  officialWebsite: '前往官網',
  lengTools: '借貸工具',
  lengToolsDesc01: '多種加密貨幣選擇',
  lengToolsDesc02: '靈活的借貸期限',
  lengToolsDesc03: '自動匹配借貸交易',
  lengToolsDesc04: '高安全性和透明度',
  lengToolsDesc05: '良好的流動性和市場深度',
  userProtocolTitle: '用户協議',
  termsOfServiceAutoDDHContentL: '<p>為了使用網頁交易、高階交易工具、靈動策略和實盤展示功能，您同意並授權我們的服務器讀取您的倉位信息並進行數據處理和交易執行。</p><p>本應用提供給您一個管理頭寸組合的工具。您應評估後獨立承擔風險使用。您瞭解並同意本應用有高度的財務風險，您有足夠的經驗和知識瞭解本應用的風險。</p><p>我們會盡力提供一款運行順利的應用。在我們認為需要時，會盡快修復錯誤和缺陷並對應用進行升級。我們不對任何錯誤或缺陷造成的後果承擔責任。使用本應用帶來的任何風險、損失或傷害都由您獨自承擔。</p><p>在數字貨幣交易所宕機或維護時，我們無法向交易所傳遞交易信號。這個風險由您獨自承擔。</p>',
  termsOfServiceAPIKeyContentL: '<p>當您使用API密鑰時，請確保其被妥善存放和傳輸。暴露您的密鑰會導致您的賬户被盜。以下為推薦的最優做法：</p><li>我們推薦您使用IP白名單，這樣可以降低泄露密鑰的影響。</li><li>刪除不需要的API密鑰，從而降低被攻擊的敞口。</li><li>定期重置您的API密鑰。</li><p>請注意：Greeks.live及其應用不存放您任何API密鑰信息。您將是安全使用您API密鑰的單一責任人。</p>',
  advanceToolsTips: '提供了更大的靈活性和風險管理能力。',
  learnMoreDatalab: '瞭解更多關於Greeks.live數據實驗室',
  learnMorePlayback: '瞭解更多關於Greeks.live行情回放',
  downloadApp: '下載APP',
  tips: '提示',
  copySuccess: '複製下載鏈接成功',
  download: '下載',
  openInBrowser: '請使用瀏覽器打開',
  oldVer2: '舊版本',
}



const zh_tc = {
  message: message,
}

export default zh_tc
