const message = {
  englishTextOne: "Professional Option Traders' Tool Kit",
  englishTextTwo: "HomePage",
  englishTextThree: "Block Trading",
  englishTextFour: "Newcomers’Guide",
  englishTextFive: "Contact Us",
  englishTextSix: "Twitter",
  englishTextSeven: "Telegram (CN)",
  englishTextEight: "Telegram (EN)",
  englishTextNine: "About Us",
  englishTextTen: "AMBASSADOR PROGRAM",
  englishTextEleven: "Connect",
  englishTextTwelve: "TRADING WEBSITE",
  englishTextThirteen: "One click delta hedge",
  englishTextFourteen: "One click chase orders",
  englishTextFifteen: "Open orders sorting",
  AutoLendingTool: "The Auto Lending Tool",
  englishTextSixteen: "Customize columns",
  englishTextSeventeen: "POSITION VISUALIZATION",
  englishTextEighteen: "PnL Surface View",
  englishTextNineteen: "Greeks Surface View",
  englishTextTwenty: "Maintenance Margin Stress Test",
  englishTextTwentyOne: "PnL Heatmap",
  englishTextTwentyTwo: "Position Visualization",
  englishTextTwentyFour: "DATA LAB",
  englishTextTwentyFive: "IV/HV/VRP data",
  englishTextTwentySix: "Open Interest Data",
  englishTextTwentySeven: "Option Flow Data",
  englishTextTwentyEight: "IV Skew/Surface",
  dataLabDesc06: "Max Pain",
  englishTextTwentyNine: "PLAY BACK",
  playBackDesc01: "Replay recent options market activity",
  playBackDesc02: "Review how IV moved step-by-step",
  englishTextThirty: "ADVANCED TRADING TOOLS",
  englishTextThirty02: "Advanced Trading Tools",
  advanceToolsDesc01: "Auto DDH",
  advancedToolsDesc02: "Maker Assistant",
  advancedToolsDesc03: "Future Swap",
  advancedToolsDesc04: "Auto Synthetic Delivery",
  englishTextThirtyOne: "Terms of Service",
  englishTextThirtyTwo: "Deribit",
  englishTextThirtyThree: "BIT",
  trade: 'Trade',
  baseTrade: 'Screen Trading',
  socialTrading: 'Social Trading',
  joinTg: 'Join & discuss with the best options traders',
  relatedProducts: 'Our Products',
  whyOptions: 'WHY CRYPTO OPTIONS?',
  whyOptionsDesc01: 'Super high leverage upto 2,000x without risk of forced liquidation (standard margin buy options only);',
  whyOptionsDesc02: 'Effective portfolio hedge while reserve the potential of upsides;',
  whyOptionsDesc03: 'Best yield tool even in bear market;',
  whyOptionsDesc04: 'New trading dimension of volatility. Straddle, strangle, butterfly, and options trading with active delta hedge;',
  feature: 'POPULAR FEATURES',
  startDate: 'Started From',
  runningTime: 'Safe Running Time',
  days: 'days',
  backed: 'BACKED BY THE BEST',
  subtitle: 'ONE unified UI for different exchanges',
  officialWebsite: 'Official Website',
  lengTools: 'LENDING FACILITY',
  lengToolsDesc01: 'Variety Cryptocurrency Options',
  lengToolsDesc02: 'Flexible Loan Duration',
  lengToolsDesc03: 'Automatic Matching for Loan Transactions',
  lengToolsDesc04: 'High Security and Transparency',
  lengToolsDesc05: 'Good Liquidity and Market Depth',
  userProtocolTitle: 'Terms of Service',
  termsOfServiceAutoDDHContentL: '<p>In order to facilitate the web trading, advanced trading tools, flexibo and live trading sharing function, it is necessary our server to read your position information and make relevant data process and decide trading actions. By agreeing this terms of service, you acknowledge and accept our access to your positional information.</p><p>We do our utmost to offer a smoothly functioned application. We will remedy any bug and other defect and make available updates of the application at our sole discretion and on a best efforts basis. We will not be liable for any consequences causing from any bug or defect. Any risk, loss or damage arising from using this application, is solely on your own account.</p><p>In case of crypto exchange break down or maintenance, we are not able to submit trading orders into the exchange, this risk is solely on your own account.</p>',
  termsOfServiceAPIKeyContentL: '<p>When you use API keys, ensure that they are kept secure during both storage and transmission. Publicly exposing your credentials can result in your account being compromised, which could lead to unexpected charges on your account. To help keep your API keys secure, follow these best practices:</p><li>We recommend to set up API key IP Whitelist. By adding IP Whitelist, you can reduce the impact of a compromised API key.</li><li>Delete unneeded API keys to minimize exposure to attacks.</li><li>Regenerate your API keys periodically.</li><p>Greeks.live and its applications donot store any of your API keys info. You are solely responsible to keep your API keys safe during usage. </p>',
  advanceToolsTips: 'Provides greater flexibility and risk management capabilities.',
  learnMoreDatalab: 'Learn more about Greeks.live Data Lab',
  learnMorePlayback: 'Learn more about Greeks.live Play Back',
  downloadApp: 'Download APP',
  tips: 'Tips',
  copySuccess: 'Copying the download link succeeded',
  download: 'Download',
  openInBrowser: 'Please open it with browser',
  oldVer2: 'Legacy',
}

const en = {
  message: message,
}

export default en
