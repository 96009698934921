//i18n.js
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from './langs'

Vue.use(VueI18n)
const i18n = new VueI18n({
  silentTranslationWarn: true, // 关闭翻译失败时的控制台警告
  locale: 'en',
  messages
})

export default i18n
